var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "footer" }, [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "link-container" }, [
          _c("ul", { staticClass: "menus" }, [
            _c("li", { staticClass: "menu" }, [
              _c(
                "a",
                { staticClass: "service-name", attrs: { href: "/about" } },
                [_vm._v("회사소개")]
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "menu" }, [
              _c(
                "a",
                { staticClass: "service-name", attrs: { href: "/faq" } },
                [_vm._v("자주 묻는 질문")]
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "menu" }, [
              _c(
                "a",
                { staticClass: "service-name", attrs: { href: "/business" } },
                [_vm._v("더밀크 구독안내")]
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "menu" }, [
              _c(
                "a",
                { staticClass: "service-name", attrs: { href: "/terms" } },
                [_vm._v("이용약관")]
              )
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "menu" }, [
              _c(
                "a",
                { staticClass: "service-name", attrs: { href: "/privacy" } },
                [_vm._v("개인정보처리방침")]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "side-container" }, [
            _c("ul", { staticClass: "social-service" }, [
              _c("li", { staticClass: "item" }, [
                _c(
                  "a",
                  {
                    staticClass: "service-name",
                    attrs: {
                      href: "https://www.facebook.com/themiilkinc",
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "Facebook",
                      attrs: {
                        src:
                          "https://assets.themiilk.com/common/sns-facebook.svg"
                      }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "item" }, [
                _c(
                  "a",
                  {
                    staticClass: "service-name",
                    attrs: {
                      href: "https://www.youtube.com/themiilk",
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "Youtube",
                      attrs: {
                        src:
                          "https://assets.themiilk.com/common/sns-youtube.svg"
                      }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "item" }, [
                _c(
                  "a",
                  {
                    staticClass: "service-name",
                    attrs: {
                      href: "https://www.linkedin.com/company/the-miilk",
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "LinkedIn",
                      attrs: {
                        src:
                          "https://assets.themiilk.com/common/sns-linkedin.svg"
                      }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "item" }, [
                _c(
                  "a",
                  {
                    staticClass: "service-name",
                    attrs: {
                      href: "https://twitter.com/TheMiilkInc",
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "Twitter",
                      attrs: {
                        src:
                          "https://assets.themiilk.com/common/sns-twitter.svg"
                      }
                    })
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "address label-xsmall" }, [
              _vm._v("\n          1900 Embarcadero Rd #202 "),
              _c("br"),
              _vm._v("\n          Palo Alto, CA 94303\n        ")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "copyright" }, [
          _vm._v("© The Miilk. All rights reserved.")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }